<template>
  <el-dialog
      title="添加系列"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="系列名称" prop="seriesName">
            <el-input v-model="form.seriesName"></el-input>
          </el-form-item>
          <el-form-item label="系列编号" prop="seriesName">
            <el-input v-model="form.seriesNum"></el-input>
          </el-form-item>
          <el-form-item label="品牌图片" >
            <UploadImages @files="getFiles" :img-size="1"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSeriesCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
import UploadImages from "@/components/image/uploadImages.vue";

export default {
  name: "store-series-add",
  components: {UploadImages},
  data() {
    return {
      form: {
        storeBrandId:this.storeBrand.id,
        seriesName: "",
        seriesNum: "",
        createDate: new Date(),
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        seriesName: [
          {required: true, message: '请输入系列名称', trigger: 'change'},
        ],
        seriesNum: [
          {required: true, message: '请输入系列编号', trigger: 'change'},
        ],
      },
      dialogState: this.state,
      uploadImages:[],
      uploadImageNames:[],
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    storeBrand:{
      type: Object,
      required: true,
    }
  },
  methods: {
    //检查订单校验
    addSeriesCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addSeries();
        }
      })
    },
    //添加店铺
    addSeries: function () {
      let value = "是否添加该系列?"
      this.$confirm(value, '添加系列:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.uploadStyleImage().then(value => {
          if (!value) {
            this.$message({
              showClose: true,
              message: "图片上传发生错误,请检查后重新上传",
              type: 'error',
              duration: 1500,
            })
          } else {
            this.$axios({
              method: "PUT",
              url: "/storeSeries/addSeries",
              data: {
                storeBrandId:this.form.storeBrandId,
                seriesName: this.form.seriesName,
                seriesNum: this.form.seriesNum,
                createDate: this.form.createDate,
                seriesImg: this.uploadImageNames.length < 1?"":this.uploadImageNames[0],
                tenantCrop: this.form.tenantCrop,
              }
            }).then(response => {
              if (response.data.code === 200) {
                this.$message.success("添加成功!")
                let self = this;
                setTimeout(function () {
                  self.close()
                }, 1500)
              } else {
                this.$message.error(response.data.msg)
              }
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.uploadImages.length < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildFiles();
        this.$upload.getTemporaryKey(this.$upload.brandImage).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },
    //构建多文件上传时的格式
    buildFiles() {
      let buildFiles = [];
      this.uploadImages.forEach(k => {
        let arr = k.name.split(".");
        let fileName = this.$md5(arr[0] + new Date()) + "." + arr[arr.length - 1];
        this.uploadImageNames.push(fileName);
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.brandImage), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: k.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },
    getFiles(file) {
      this.uploadImages = file;
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>