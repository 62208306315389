<template>
  <div>
    <el-upload
      action="#"
      list-type="picture-card"
      accept="image/*"
      :multiple="true"
      :on-change="handleSuccess"
      :file-list="files"
      :auto-upload="false">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url" alt=""
        >
        <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>
    </el-upload>
  </div>
</template>
<script>

export default {
  name: "store-style-add",
  components: {

  },
  created() {

  },
  props:{
    imgSize:{
      type:Number,
      required: true,
    }
  },
  data() {
    return {
      files:[],
    }
  },
  methods:{
    handleSuccess(file){
      this.files.push(file)
      if (this.imgSize>0&&this.files.length>this.imgSize){
        this.$message.warning("超出上传数量限制!")
        this.files = this.files.filter(obj => obj.url !== file.url);
        return;
      }
      this.$emit("files",this.files)
    },
    handleRemove(file) {
      this.files = this.files.filter(obj => obj.url !== file.url);
      this.$emit("files",this.files)
    },
    handlePictureCardPreview(file) {
      this.$viewerApi({
        images: [file.url],
      })
    },
  },
}
</script>
<style scoped>

</style>