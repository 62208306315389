<template>
<div>
  <el-row style="margin-top: 10px;margin-left: 10px">
    <el-table
        :data="table.libBrandTenantList"
        border
        max-height="700"
        :row-style="{height: '38px'}"
        :cell-style="{padding: '0'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
        :default-sort="{prop: 'id', order: 'descending'}"
        style="width: 100%">
      <el-table-column prop="createDate" label="时间" align="center"/>
      <el-table-column prop="libBrandName" label="品牌名称" align="center" />
      <el-table-column width="350" align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="warning" size="mini" @click="openSeriesAdd(scope.row)">添加系列</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
  <el-row style="margin-top: 5px">
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="this.table.limit"
        layout="prev, pager, next,total, jumper, sizes"
        :total="table.total">
    </el-pagination>
  </el-row>
  <seriesAdd v-if="dialog.seriesAddState"  :state="dialog.seriesAddState" :libBrand="dialog.libBrand" @close="close"/>
</div>
</template>

<script>
import seriesAdd from "@/pages/store/brand-list/store-series-add.vue";
export default {
  name: "my-lib-brand-list",
  data() {
    return {
      table: {
        libBrandTenantList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog:{
        libBrand:{},
        seriesAddState:false,
      },
      tenantCrop: localStorage.getItem("tenantCrop"),
    }
  },
  components: {
    seriesAdd
  },
  created() {
    this.queryBrandTenantList();
  },
  methods: {
    //查询客户列表
    queryBrandTenantList: function () {
      this.$axios({
        method: "GET",
        url: "/libraryBrandTenant/queryList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop:this.tenantCrop
        }
      }).then(response => {

        this.table.total = response.data.data.total;
        this.table.libBrandTenantList = response.data.data.list;
        console.log(this.table.libBrandTenantList)
      })
    },
    openSeriesAdd(data){
      this.dialog.seriesAddState=true;
      this.dialog.libBrand=data;
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryBrandTenantList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryBrandTenantList()
    },
    close: function () {
      this.dialog.seriesAddState=false;
    },
  }
}
</script>

<style scoped>

</style>